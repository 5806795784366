<template>
  <Layout tituloPagina="Inventario | Solicitudes">
    <div class="row">
      <div class="col-lg-12 text-right">
        <router-link :to="{ name: 'nuevaSolicitud' }" class="btn btn-success">
          <i class="mdi mdi-plus-thick"></i>
          Nueva solicitud de artículos
        </router-link>
      </div>
    </div>
    <br />
    <div class="card">
      <div class="card-body" style="min-height: 475px;">
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-3">
            <div class="input-group">
              <span class="input-group-text">
                <i class="bx bx-filter"></i>&nbsp;Estado
              </span>
              <select class="form-select" v-model="estado" @change="refrescarSolicitudes()">
                <option :value="0">Todos</option>
                <option :value="1">Pendiente</option>
                <option :value="2">En proceso</option>
                <option :value="3">Cerrada</option>
                <option :value="4">Cancelado</option>
              </select>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-3" v-if="tienePermiso('cmpFiltroUsuarioSolicitud', 'componente')">
            <div class="input-group">
              <span class="input-group-text">
                <i class="mdi mdi-account"></i>&nbsp;Usuario
              </span>
              <select class="form-select" v-model="id_solicitante" @change="refrescarSolicitudes()">
                <option :value="null">Todos</option>
                <option 
                  v-for="usuario in usuarios"
                  :key="usuario.id"
                  :value="usuario.id"
                >
                  {{usuario.nombre}}
                  {{usuario.apellido_paterno}}
                  {{usuario.apellido_materno}}
              </option>
              </select>
            </div>
          </div>
          <div class="col-lg-1 col-md-6 p-2">
            <div class="form-check" style="display: inline-block;">
              <input
                id="dia" type="checkbox"
                @change="checkedDia()"
                class="form-check-input form-check-input-success"
              />
              <label
                for="dia" class="form-check-label"
                :class="{ 'text-success': dia == true }"
              >
                <strong>Día</strong>
              </label>
            </div>
          </div>
          <div class="col-lg-1 col-md-6 p-2">
            <div class="form-check" style="display: inline-block;">
              <input
                id="personalizado" type="checkbox"
                @change="checkedPersonalizado()"
                class="form-check-input form-check-input-info"
              />
              <label
                for="personalizado" class="form-check-label"
                :class="{ 'text-info': personalizado == true }"
              >
                <strong>Personalizado</strong>
              </label>
            </div>
          </div>
        </div>
        <div class="row" v-if="dia == true || personalizado == true">
          <div class="offset-lg-3 col-lg-3 col-md-6">
            <label for="">Fecha inicial</label>
            <Datepicker
              ref="fecha_inicial"
              v-model="fecha_inicial"
              placeholder="dd/mm/aaaa"
              :class="'form-control bg-white'"
              :inputFormat="'dd/MM/yyyy'"
            />
          </div>
          <div class="col-lg-3 col-md-6" v-if="dia != true || personalizado == true">
            <label for="">Fecha Final</label>
            <Datepicker
              ref="fecha_final"
              v-model="fecha_final"
              placeholder="dd/mm/aaaa"
              :class="'form-control bg-white'"
              :inputFormat="'dd/MM/yyyy'"
              v-if="dia != true || personalizado == true"
            />
          </div>

          <div class="col-lg-3 col-md-6 pt-4">
            <button class="btn btn-secondary"
              @click="refrescarSolicitudes()"
            >
              <i class="mdi mdi-refresh"></i>
            </button>
          </div>
        </div>
        <br>
        <div class="table-responsive">
          <table id="tblListaSolicitudes" class="table table-hover table-nowrap">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre de solicitante</th>
                <th>Fecha de solicitud</th>
                <th>Estatus</th>
                <th>Cantidad de artículos solicitada</th>
                <th style="width:180px" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="solicitud in solicitudes" :key="solicitud.id">
                <td class="fw-semibold">
                  <router-link
                    :to="{ name: 'visorSolicitud', params: { id: solicitud.id } }"
                  >
                    {{solicitud.id}}
                  </router-link>
                </td>
                <td class="fw-semibold">
                  {{solicitud.nombre_solicitante}}
                </td>
                <td>
                  {{formatoFecha(solicitud.created_at)}}
                </td>
                <td>
                  <span
                    class="badge bg-gradient font-size-12" 
                    :class="{
                      'bg-info':solicitud.estado==1,
                      'bg-warning':solicitud.estado==2,
                      'bg-primary':solicitud.estado==3,
                      'bg-danger':solicitud.estado==4
                    }"
                  >
                    {{solicitud.estado==1?'Pendiente':''}}
                    {{solicitud.estado==2?'En proceso':''}}
                    {{solicitud.estado==3?'Cerrada':''}}
                    {{solicitud.estado==4?'Cancelada':''}}
                  </span>
                </td>
                <td>
                  {{solicitud.cantidad_articulos_solicitados}}
                </td>
                <td class="text-center">
                  <router-link
                    :to="{ name: 'visorSolicitud', params: { id: solicitud.id } }"
                    class="btn btn-info btn-sm"
                  >
                    <i class="fa fa-eye"></i>
                    Ver
                  </router-link>
                  <button class="btn btn-danger btn-sm" 
                    v-show="solicitud.estado == 1" @click="cancelarSolicitud(solicitud)">
                    <i class="fa fa-ban"></i>
                    Cancelar
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="text-center">
      <paginador
        :pag="page"
        :totpag="totpag"
        :epp="epp"
        @cargar-pagina="cargarPagina"
        @epp-actualizado="
          epp = $event,
          refrescarSolicitudes()
        "
      ></paginador>
    </div>
    <br>
  </Layout>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import SolicitudArticulosSrv from '@/services/inventario/SolicitudArticulosSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import moment from 'moment'
import Datepicker from 'vue3-datepicker'
import { todoGetters } from "@/state/helpers"
import Swal from 'sweetalert2'
import Layout from "@/views/layouts/main"
export default {
  name: 'ListadoSolicitudesArticulos',
  components: { Layout, Paginador, Datepicker },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoSolicitudesArticulos.por_pagina') || 10),
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
      id_solicitante: null,
      estado: 1,
      solicitudes:[],
      usuarios:[],
      dia: false,
      personalizado: false,
      fecha_inicial: null,
      fecha_final: null,
    }
  },
  computed:{
    ...todoGetters
  },
  watch: {
    fecha_inicial : function(newVal, oldVal) {
      var self = this 
      self.refrescarSolicitudes()
    },
    fecha_final : function(newVal, oldVal) {
      var self = this 
      self.refrescarSolicitudes()
    }
  },
  created() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
    if (this.$route.query.id_solicitante) this.id_solicitante = parseInt(this.$route.query.id_solicitante)
    if (this.$route.query.estado) this.estado = parseInt(this.$route.query.estado)

    if(!self.tienePermiso('inventario.solicitudesArticulos.solicitudesJson', 'accion')){
      iu.msg.warning(
        `No tienes permiso para acceder a <strong style="color: #e65853">inventario.solicitudesArticulos.solicitudesJson</strong>,
        revisa los permisos de rol con tu administrador de sistema`)
      
      setTimeout(() => {
        self.$router.replace({
          name:'inicio'
        })
      }, 50);
    }
    //Carga de la fecha inicial del sistema
    if (self.fecha_inicial == null) {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        // self.fecha_inicial = response.data
        self.fecha_inicial = new Date(moment(response.data).format())
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }

    //Carga de la fecha inicial del sistema
    if (self.fecha_final == null) {
      SistemaSrv.fecha({formato: 'Y-m-d'}).then(response => {
        // self.fecha_final = response.data
        self.fecha_final = new Date(moment(response.data).format())
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    }

    self.refrescarSolicitudes()
    
    if(self.tienePermiso('sistema.usariosJson', 'accion')) self.cargarUsuarios()
  },
  methods: {
    cancelarSolicitud: function(solicitud) {
      var self = this

      if(!self.tienePermiso('inventario.solicitudesArticulos.cancelar', 'accion')){
        iu.msg.warning(
          `No tienes permiso para acceder a <strong style="color: #e65853">inventario.solicitudesArticulos.cancelar</strong>,
          revisa los permisos de rol con tu administrador de sistema`)
        return
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Cancelar solicitud seleccionado?",
        html: `La cancelacion de la solicitud de <br><strong>${solicitud.nombre_solicitante}</strong><br> es una acción irrevercible.`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SolicitudArticulosSrv.cancelar(solicitud.id).then(response => {
            swal.fire("Cancelada!", "Se ha cancelado correctamente la solicitud de "+solicitud.nombre_solicitante, "success");
            self.refrescarSolicitudes()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar la solicitud de '+solicitud.nombre_solicitante
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    solicitudCancelada: function(){
      var self = this
      self.refrescarSolicitudes()
    },
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarSolicitudes({ page: n })
    },
    cargarUsuarios: function() {
      var self = this

      let params = {
        todos: self.todos
      }

      UsuarioSrv.usuariosJSON(params).then(response => {
        self.usuarios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    checkedDia: function() {
      var self = this

      if(self.dia != true) self.dia = true
      else self.dia = false
      if (self.dia == true) {
        document.getElementById("dia").checked = true
      } else {
        document.getElementById("dia").checked = false
      }

      if(self.dia == true){
        document.getElementById("personalizado").checked = false
        self.personalizado = false
      }
      
      self.refrescarSolicitudes()
    },
    checkedPersonalizado: function() {
      var self = this

      if(self.personalizado != true) self.personalizado = true
      else self.personalizado = false
      if (self.personalizado == true) {
        document.getElementById("personalizado").checked = true
      } else {
        document.getElementById("personalizado").checked = false
      }

      if(self.personalizado == true){
        document.getElementById("dia").checked = false
        self.dia = false
      }
      self.refrescarSolicitudes()
    },
    formatoFecha(mt) {
      return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
    },
    formatoFechaSolicitud(mt){
      return moment(mt).format('YYYY-MM-DD')
    },
    refrescarSolicitudes: function() {
      var self = this

      iu.spinner.mostrar('#tblListaSolicitudes')

      let query = {
        dato: self.dato,
        page: self.page,
        epp: self.epp,
        estado: self.estado
      }

      if(self.tienePermiso('cmpFiltroUsuarioSolicitud', 'componente')){
        Object.assign(query,{id_solicitante: self.id_solicitante})
      }

      this.$router.replace({
        query: query
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        dato: self.dato,
        id_solicitante: self.id_solicitante,
        con_cantidad_articulos_solicitados: true,
        con_cantidad_articulos_entregados:true
      }

      if(self.estado != 0) Object.assign(params,{estado:self.estado})

      if(!self.tienePermiso('cmpFiltroUsuarioSolicitud', 'componente')){
        Object.assign(params,{id_solicitante: self.$store.state.todo.usuario.id})
      } else {
        Object.assign(params,{id_solicitante: self.id_solicitante})
      }

      if(self.dia && !self.personalizado){
        if(self.formatoFechaSolicitud(self.fecha_inicial) == 'Invalid date'){
          iu.msg.warning("El campo \"Fecha inicial\", requiere una fecha válida")
          iu.spinner.ocultar('#tblListaSolicitudes')
          return
        }

        Object.assign(params,{
          fecha_inicial: self.formatoFechaSolicitud(self.fecha_inicial),
          fecha_final: self.formatoFechaSolicitud(self.fecha_inicial)
        })
      }

      if(self.personalizado){
        if(self.formatoFechaSolicitud(self.fecha_inicial) == 'Invalid date'){
          iu.msg.warning("El campo \"Fecha inicial\", requiere una fecha válida")
          iu.spinner.ocultar('#tblListaSolicitudes')
          return
        }

        if(self.formatoFechaSolicitud(self.fecha_final) == 'Invalid date'){
          iu.msg.warning("El campo \"Fecha final\", requiere una fecha válida")
          iu.spinner.ocultar('#tblListaSolicitudes')
          return
        }
        
        Object.assign(params,{
          fecha_inicial: self.formatoFechaSolicitud(self.fecha_inicial),
          fecha_final: self.formatoFechaSolicitud(self.fecha_final)
        })
      }

      SolicitudArticulosSrv.solicitudesJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoSolicitudesArticulos.por_pagina', self.epp)
        let page = response.data
        self.solicitudes = page.data
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarSolicitudes()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (() => {
        iu.spinner.ocultar('#tblListaSolicitudes')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaSolicitudes')
  }
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
.form-check-input-info:checked {
  background-color: #57C9EB;
  border-color: #57C9EB;
}
.pt-4 {
  padding-top: 1.8rem !important;
}
</style>